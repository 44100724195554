* {
  margin: 0px;
  padding: 0px;
}

.App {
  text-align: center;
}

.title{
  margin-top:15px;
  margin-bottom:1px;
  text-align: center;
  font-family: 'Candera Lite';
  letter-spacing: 7px;
  color:rgb(0,0,0,.7);
}

.title2{
  text-align: center;
  font-family: 'Candera Lite';
  letter-spacing: 6px;
  color:rgb(0,0,0,.7);
  margin-bottom: 0px;
}

.Logo{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.navbar {
  margin-top: -20px;
  letter-spacing: 5px;
  width: 90%;
  font-family: 'Candera Lite';
}
.link{
  margin-top:30px;
  color:rgb(0,0,0,.7) !important;
  
}

.home{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -5px;
  width: 90%;
  
}
.design{
  border-bottom: solid .05em;
  border-color:rgb(0,0,0,.5);
}

.insta{
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 80px;
  width: 80px;
  margin-bottom: 15px;
  cursor: pointer;
}

.about{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  letter-spacing: 2px;
  width: 65%;
  font-family: 'Candera Lite';
}

.projects {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  letter-spacing: 2px;
  width: 100%;
  font-family: 'Candera Lite';
}

.contact{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  letter-spacing: 2px;
  width: 100%;
  font-family: 'Candera Lite';
}

.insta1{
  cursor: pointer;
}

.mailto {
  color:black;
}

.farmhouse {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  height:350px;
  width:550px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}